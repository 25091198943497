import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import indexStyles from '../components/indexStyles.css'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 className={indexStyles.centered}>Web development &amp; DevOps stuff</h1>
    <p>This site was created with Gatsby, just to try it out a bit</p>
    <h2>Now go build something great.</h2>
  </Layout>
)

export default IndexPage
